import React, { useEffect } from "react"
import styled from "@emotion/styled"
import mq from "../style/mediaQueries"
import colors from "../style/colors"

const FlowDeskHero = () => {
  return (
    <>
      <Container>
        <a
          href="https://www.linkedin.com/company/flowdesk-france/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MailOther>@Flowdesk</MailOther>
        </a>
        <div
          style={{
            height: "22px",
            width: "80vw",
            left: 0,
            backgroundColor: colors.almostBlack,
          }}
        ></div>
        <div
          style={{
            height: "22px",
            width: "70vw",
            left: 0,
            marginTop: "16px",
            backgroundColor: colors.almostBlack,
          }}
        ></div>
        <div
          style={{
            height: "22px",
            width: "60vw",
            left: 0,
            marginTop: "16px",
            backgroundColor: colors.almostBlack,
          }}
        ></div>
        <div
          style={{
            height: "22px",
            width: "50vw",
            left: 0,
            marginTop: "16px",
            backgroundColor: colors.almostBlack,
          }}
        ></div>
        <div
          style={{
            height: "22px",
            width: "40vw",
            left: 0,
            marginTop: "16px",
            backgroundColor: colors.almostBlack,
          }}
        ></div>
        <div
          style={{
            height: "22px",
            width: "30vw",
            left: 0,
            marginTop: "16px",
            backgroundColor: colors.almostBlack,
          }}
        ></div>
        <div
          style={{
            height: "22px",
            width: "20vw",
            left: 0,
            marginTop: "16px",
            backgroundColor: colors.almostBlack,
          }}
        ></div>
        <div
          style={{
            height: "22px",
            width: "10vw",
            left: 0,
            marginTop: "16px",
            backgroundColor: colors.almostBlack,
          }}
        ></div>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 90vh;
  ${mq("nHD")} {
    height: 90vh;
  }
  ${mq("QHD")} {
    height: 90vh;
  }
  ${mq("FHD")} {
    height: 90vh;
  }
`

const Text = styled.span`
  display: flex;
  flex-direction: column;
  height: 25vh;
  margin-bottom: 64px;
  width: 100%;
  ${mq("nHD")} {
    justify-content: space-between;
    height: 20vh;
    margin-bottom: 0;
  }
  ${mq("QHD")} {
    justify-content: space-between;
    height: 25vh;
  }
`

const size = "290px"
const lspacing = "-16px"

const MailAddress = styled.span`
  color: ${colors.almostBlack};
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
  transition: all 300ms ease;
  opacity: 1;

  ${mq("HD")} {
    font-size: 96px;
    letter-spacing: -5px;
  }
  ${mq("QHD")} {
    font-size: ${size};
    letter-spacing: -32px;
    line-height: ${size};
    //fill: transparent;
    color: ${colors.almostBlack};
    &:hover {
      letter-spacing: -15px;
    }
  }
  ${mq("FHD")} {
    font-size: 120px;
    letter-spacing: -5px;
  }
`

const MailOther = styled.span`
  color: ${colors.almostBlack};
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -1px;
  transition: all 300ms ease;
  opacity: 1;

  ${mq("HD")} {
    font-size: 96px;
    letter-spacing: -5px;
  }
  ${mq("QHD")} {
    font-size: ${size};
    letter-spacing: -24px;
    line-height: ${size};
    color: ${colors.almostBlack};
  }
  ${mq("FHD")} {
    font-size: 120px;
    letter-spacing: -5px;
  }
`

export default FlowDeskHero
