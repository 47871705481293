import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
/* import Skills from "../components/skills"
import WorkList from "../components/workList"
import Hero from "../components/hero"
import Contact from "../components/contact" */
import FlowDeskHero from "../components/flowDeskHero"

const IndexPage = () => (
  <Layout>
    <Seo
      description="Emanuele Dancelli's 2021 portfolio"
      lang="en"
      meta=""
      title="2021 portfolio"
    />
    <FlowDeskHero />
    {/* <Hero />
    <WorkList />
    <Skills />
    <Contact /> */}
  </Layout>
)

export default IndexPage
